
import React from 'react'
import { useState, useEffect, useRef } from "react";
import { Modal, ModalHeader, ModalBody, Card, CardBody, ButtonToolbar, Table, ButtonGroup, Row, Col, Badge } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'primereact/button';
import classNames from 'classnames';
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm, Controller } from 'react-hook-form';
import fetchMethodRequest from '../../config/service';
import showToasterMessage from '../UI/ToasterMessage/toasterMessage';
import Loader from '../App/Loader';
import DoAutoCompleteField from './Fields/DoAutoCompleteField';
import DoInputField from './Fields/DoInputField';
import config from '../../config/config';
import DoDateField from './Fields/DoDateField';
import DoSelectField from './Fields/DoSelectField';
import apiCalls from "../../config/apiCalls"
import { Dialog } from 'primereact/dialog';


function AddQuantitesModal(props) {

    let [colored, setColored] = useState(false);
    let [header, setHeader] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [autoDetails, setAutoDetails] = useState({ name: "productName", type: "relateAutoComplete", show: true, dependent: [{ description: ['description'] }] });
    let [schema, setSchema] = useState({});
    let [isUniProductName, setIsUniProductName] = useState(undefined)
    const [formType, setFormType] = useState(props.formType);
    const [editRowDataID, setEditRowDataID] = useState(props.tableRowData);
    let [sessionExpiryModal, setSessionExpiryModal] = useState(false);
    const [isCustomVolumeDes, setIsCustomVolumeDes] = useState(false);
    const [isBattery, setIsBattery] = useState(false);
    const [isMedicine, setIsMedicine] = useState(false);
    const [isNonMedicine, setIsNonMedicine] = useState(false);
    const [isDescription, setIsDescription] = useState(false);
    const [isVolume, setIsVolume] = useState(false);
    const [allUsersData, setallUsersData] = useState(props.allUsersData);
    const [cartName, setCartName] = useState('');
    const [facilityName, setfacilityName] = useState(props.facilityName);
    const [storageUnitId, setstorageUnitId] = useState(props.storageUnitId);
    const [storageUnitName, setstorageUnitName] = useState(props.storageUnitName);
    const [facilityId, setFacilityId] = useState(props.facilityId);
    const [companyId, setCompanyId] = useState(props.companyId)
    const [tabType, settabType] = useState(props.tabType);
    const [currentInventry, setCurrentInventry] = useState(true);
    const [productName, setProductName] = useState('');
    const [productId, setProductId] = useState('');
    const [cartSubLocation, setCartSubLocation] = useState(undefined);
    const [ischeckType, setISCheckType] = useState('')

    const checkTypes = [
        { label: "Daily", value: "Daily" },
        { label: "Weekly", value: "Weekly" },
        { label: "Monthly", value: "Monthly" },
    ];

    const BatteryCheckTypes = [
        { label: "Sunday", value: "Sunday" },
        { label: "Monday", value: "Monday" },
        { label: "Tuesday", value: "Tuesday" },
        { label: "Wednesday", value: "Wednesday" },
        { label: "Thursday", value: "Thursday" },
        { label: "Friday", value: "Friday" },
        { label: "Saturday", value: "Saturday" },
    ];

    let modalClass = classNames({
        'modal-dialog--colored': colored,
        'modal-dialog--header': header,
    });


    let {
        handleSubmit,
        register,
        reset,
        setValue,
        getValues,
        formState: { errors },
        control,
    } = useForm({
        resolver: yupResolver(schema),
    });


    useEffect(() => {
        setSchema(yup.object().shape({
            productName: props.formType === "add" ? yup.object().required('Product name is a required field') : null,
            description: props.formType === "add" ? yup.object().required('Description is a required field') : null,
            lotId: yup.string().required('LotId is a required field'),
            storageUnitSubLocation: yup.string().required('StorageUnitSubLocation is a required field'),
            expirationDate: yup.string().required("Expirations is a required field"),
            addedInventory: yup.string().required("InventoryBeingAdded is a required field")

        }));
        const modal = document.querySelector('div[tabindex="-1"][style*="z-index: 1050;"]');
        if (modal) {
            modal.style.zIndex = '300';
        }

        setValue('facilityName', facilityName)
        setValue('storageUnitName', storageUnitName)
        if (formType === "edit") {
            handleAutoCompleteData(editRowDataID)
            getRowData();
        }

        getLocationSubParts();
    }, []);

    const handleChange = async (selectedValue) => {
        if (selectedValue && selectedValue === 'Weekly') {
            console.log("selectedValue && selectedValue === 'Weekly'", selectedValue, selectedValue === 'Weekly')
            await setISCheckType(selectedValue)

        }
    };
    const getUniProductName = (value) => {
        if (value) {
            setIsUniProductName(value.productName)
            setIsVolume(false);
            setIsCustomVolumeDes(false);
            setIsMedicine(false);
            setIsDescription(true);
            setIsBattery(false);
            setCurrentInventry(false)
            setProductId(value._id);
            setValue('description', '')
            setValue("volume", "")

        }
    }

    // storageUnit Cart Sub Locations 
    const getLocationSubParts = (selectedLocation) => {
        let filterCriteria = {
            direction: "desc",
            page: 1,
            limit: 20,
            criteria: []
        };
        filterCriteria['criteria']
            =
            [
                // {
                //     "key": "_id", "value": storageUnitId
                //     , "type": "eq"
                // },
                {
                    "key": "storageUnitName", "value": storageUnitName
                    , "type": "eq"
                }
            ];

        // Reference of class components
        // this.setState({
        //   cartName:
        //     selectedLocation && selectedLocation.cart
        //       ? selectedLocation.cart
        //       : selectedLocation,
        // });
        setCartName(selectedLocation && selectedLocation.cart ? selectedLocation.cart : selectedLocation)

        if (props.type == "Storage Unit Items" && selectedLocation) {
            // Reference of class components
            //   this.setState({
            //     inventoryCartId: selectedLocation.value
            //       ? selectedLocation.value
            //       : selectedLocation._id,
            //   });
        }



        return fetchMethodRequest("GET", apiCalls.cartLocations
            + `?filter=${JSON.stringify(filterCriteria)}`
        ).then(
            async (response) => {
                let subLocations = [];
                let selectedLocationName = storageUnitName
                //   selectedLocation && selectedLocation.label
                //     ? selectedLocation.label
                //     : selectedLocation;
                if (response && response.storageunits) {

                    for (let option of response.storageunits) {
                        if (selectedLocationName === option.storageUnitName) {
                            for (let value of option.storageUnitParts) {
                                subLocations.push({
                                    label: value,
                                    value: value,
                                });
                            }
                        }
                    }
                }
                setCartSubLocation(subLocations);
            }
        );

    };


    // Get row Data
    const getRowData = () => {

        setValue('storageUnitSubLocation', editRowDataID.storageUnitSubLocation)
        // setValue('count', editRowDataID.count)
        setValue('count', editRowDataID.currentInventory ? editRowDataID.currentInventory : 0)
        setValue('limit', editRowDataID.limit)
        setValue('productName', editRowDataID.productName)
        setValue('description', editRowDataID.description)
        setValue('volume', editRowDataID.volume)
    }

    // //Handle auto complete data
    let handleAutoCompleteData = async (value, name) => {
        if (value) {
            if (name == "productName" || (formType == "edit" && value.productName)) {
                getUniProductName(value);
                setValue("storageUnitSubLocation", "")
                setValue('limit', "");
                setValue("count", "");

            }

            if (name === "description" || (formType == "edit" && value.description)) {
                setValue("volume", value.volume)

                if (value && value.categoryType) {
                    if (value.categoryType) {
                        if (value.categoryType == "Medicine" && value.batteryExists !== true) {
                            setIsMedicine(true);
                            setIsNonMedicine(false);
                            setIsBattery(false);
                            setIsVolume(true);

                        } else if (value.categoryType == "Non-Medicine" && value.batteryExists !== true) {
                            setIsMedicine(false);
                            setIsNonMedicine(true);
                            setIsBattery(false);

                        }
                        else if (value.batteryExists === true) {
                            setIsMedicine(false);
                            setIsNonMedicine(false);
                            setIsBattery(true);
                        }
                    }
                }

                if (name == "description") {
                    setProductId(value._id);
                    // setProductName(value.productName)
                }
            }
        }
    }



    const getCountData = (cartSubLocations) => {
        if ((storageUnitId != null) && (productId != null && productId !== "") && cartSubLocation != null) {
            let body = {
                storageUnitId: storageUnitId,
                productId: formType == "add" ? productId : productId.productId,
                storageUnitSubLocation: cartSubLocations,
            };
            return fetchMethodRequest(
                "POST",
                "productQuantities/currentInventoryCount",
                body
            ).then(async (response) => {
                if (response && ((response.count || response.count == 0) && (response.required || response.required == 0))) {
                    setValue("count", response.count);
                    setValue("limit", response.required)
                } else {
                    setValue("count", 0);
                    setValue("limit", 0)

                }
            });
        }
    };

    const getRequiredInventryBasedOnCartSubLoc = () => {
        if ((productId !== null && productId !== "" && (typeof productId === "string")) && (props.tabType != null)) {
            let filterCriteria = {
                direction: "desc",
                page: 1,
                limit: 20,
                criteria: []
            };

            if (tabType != null) {
                filterCriteria['criteria']
                    =
                    [
                        { "key": "productId", "value": productId, "type": "eq" },
                        {
                            "key": "storageUnitType", "value": props.tabType == "Cart Location" ?
                                "cartLocation" : props.tabType == "Supply Cabinets" ? "supplyCabinet" : "orSuit"
                            , "type": "eq"
                        },
                        {
                            "key": "storageUnitId", "value": storageUnitId
                            , "type": "eq"
                        }
                    ];
            }

            // return fetchMethodRequest("GET", apiCalls.minInventoryByProducts
            //     + `?filter=${JSON.stringify(filterCriteria)}`
            // ).then(
            //     (response) => {
            //         if (response) {
            //             let requiredCount;
            //             if (response) {
            //                 if (response.mininventorybyproducts && response.mininventorybyproducts.length > 0) {
            //                     requiredCount = response.mininventorybyproducts[0].required;
            //                     setValue("limit", requiredCount);
            //                 } else {
            //                     setValue("limit", 0);

            //                 }
            //             }
            //         }
            //     }
            // );
        }

    }

    const getRequiredQuantityBasedOnCartSubLoc = (value) => {
        if (value) {
            getCountData(value);
            getRequiredInventryBasedOnCartSubLoc();
        }
    }
    //custome volume 
    const customVolumeInventory = () => {
        setIsCustomVolumeDes(!isCustomVolumeDes);
    };


    // const submit = (values) => {
    //     if (values && Object.keys(values).length > 0) {
    //         saveDataToServer(values);
    //     } else {
    //         return;
    //     }
    // }

    const submit = (values) => {
        // Convert batteryCheckDay and checkType to lowercase if they exist
        if (values && Object.keys(values).length > 0) {
            if (values.batteryCheckDay) {
                values.batteryCheckDay = values.batteryCheckDay.toLowerCase();
            }
            if (values.checkType) {
                values.checkType = values.checkType.toLowerCase();
            }

            saveDataToServer(values);
        } else {
            return;
        }
    }



    const saveDataToServer = async (formValues) => {
        let userBody = Object.assign({}, formValues);
        const loginCredentials = localStorage.getItem("loginCredentials");
        const credentialsObject = JSON.parse(loginCredentials);
        let loginRole = credentialsObject.role
        const companyId = credentialsObject._id;
        userBody.loginRole = `${loginRole}`;
        userBody.companyId = loginRole === 'Admin' ? `${companyId}` : loginRole === 'Super Admin' ? `${props.adminCompanyId}` : null;
        let validationExists;
        setIsLoading(true);
        if (formValues) {

            let method, apiUrl;
            if (formType === 'edit') {
                method = 'POST';
                apiUrl = props.productQuantitesUrl

            } else if (formType === 'view') {
                delete userBody.email
                delete userBody.password;
                method = 'PUT';
                apiUrl = `${props.apiUrl}/${editRowDataID._id}`;
            } else {
                method = 'POST';
                apiUrl = props.productQuantitesUrl
            }


            let storageUnitIds = storageUnitId ? storageUnitId : null
            let storageUnitNames = storageUnitName ? storageUnitName : null
            let facilityIds = facilityId ? facilityId : null
            const storageTypes = { "Cart Location": 'cartLocation', "Supply Cabinets": 'supplyCabinet', "OR Suites": 'orSuit' };
            // tabType == "Cart Location" ? "cartLocation" : tabType == "Supply Cabinets" ? "supplyCabinet"  : tabType == "OR Suites" ?  'orSuit' : null
            let storageUnitType = storageTypes[tabType]

            userBody.facilityId = facilityIds
            userBody.storageUnitId = storageUnitIds;
            userBody.storageUnitName = storageUnitNames;
            userBody.storageUnitType = storageUnitType;
            userBody.addedInventory = formValues.addedInventory

            if (formValues.productName && formValues.productName.categoryType === "Non-Medicine") {
                userBody.batteryExists = true
            } else {
                userBody.batteryExists = false
            }
            if (formType === "edit") {
                userBody.productId = props.tableRowData.productId;
                userBody.description = editRowDataID.description;
            } else {
                userBody.productId = formValues.productName._id;
                userBody.description = formValues.description.description;
            }



            return fetchMethodRequest(method, apiUrl, userBody)
                .then(async (response) => {
                    let sessionexpired = await localStorage.getItem('sessionexpired')
                    if (sessionexpired === "true") {
                        setSessionExpiryModal(true);
                    }
                    if (response && response.respCode) {
                        await props.getDataFromServer(props.filterCriteria, props.categoryType);
                        showToasterMessage(response.respMessage, 'success');
                        if (props.displayViewOfForm === 'modal') {
                            props.closeFormModal('save', response.quantityId);
                        } else {
                            if (formType !== 'add') {
                                // window.location.href = `/${props.routeTo}`;
                            } else {
                                props.closeFormModal('save', response.quantityId);
                            }

                        }
                        clearFormFields();
                        props.reset();
                    } else if (response && response.errorMessage) {
                        showToasterMessage(response.errorMessage, 'error');
                    }

                    setIsLoading(false);
                }).catch((err) => {
                    return err;
                });

        } else {
            return
        }

    }

    const getFields = () => {
        return (
            <>
                <div className="col-md-6 mt-2">
                    <Controller
                        name={'productName'}
                        control={control}
                        render={({ field, fieldState }) => (
                            <DoAutoCompleteField
                                markReq={true}
                                input={field}
                                id={field.id}
                                name={field.name}
                                field={field}
                                filterField={false}
                                filterValue={false}
                                filterType={false}
                                multiple={false}
                                fieldState={fieldState}
                                errors={errors}
                                screen={props.type}
                                searchApi={'products'}
                                searchField={'productName'}
                                allow={props.allowDuplicates}
                                filterFieldType={null}
                                onChange={(event) => handleAutoCompleteData(event, 'productName')}
                                placeholder={'Product Name'}
                                label={'Product Name'}
                                item={autoDetails}
                                getValues={getValues}
                                isProductName={isUniProductName}
                                isDisable={formType === "edit" ? true : false}
                            />)}
                    />
                </div>

                {(isDescription || isUniProductName) &&
                    <div className="col-md-6 mt-2">
                        <Controller
                            name={'description'}
                            control={control}
                            render={({ field, fieldState }) => (
                                <DoAutoCompleteField
                                    markReq={true}
                                    input={field}
                                    id={field.id}
                                    name={field.name}
                                    field={field}
                                    filterField={false}
                                    filterValue={false}
                                    filterType={false}
                                    multiple={false}
                                    fieldState={fieldState}
                                    errors={errors}
                                    screen={props.type}
                                    searchApi={'products/descriptionList'}
                                    searchField={'description'}
                                    allow={props.allowDuplicates}
                                    filterFieldType={null}
                                    placeholder={'Description'}
                                    onChange={(event) => handleAutoCompleteData(event, 'description')}
                                    label={'Description'}
                                    item={autoDetails}
                                    getValues={getValues}
                                    call={'POST'}
                                    isProductName={isUniProductName}
                                    isDisable={formType == "edit" ? true : false}
                                />)}
                        />
                    </div>}


                {isVolume &&
                    <div className="col-md-6 mt-2">
                        <div className="row" style={{ alignItems: "center" }}>
                            <div className="col-md-10">
                                <Controller
                                    name={'volume'}
                                    control={control}
                                    render={({ field, fieldState }) => (
                                        <DoInputField
                                            markReq={true}
                                            input={field}
                                            id={field.id}
                                            name={field.name}
                                            label={<div>Volume</div>}
                                            field={field}
                                            type={"text"}
                                            fieldState={fieldState}
                                            errors={errors}
                                            isDisable={true}
                                            defVal={''}
                                            placeholder={"Volume"}
                                        />
                                    )}
                                />
                            </div>
                            <div className="col-md-2 pt-2">
                                <Button rounded className="drugsfield_add_icon"><FontAwesomeIcon
                                    // icon={faPlus}
                                    icon="plus"
                                    size='lg'
                                    data-toggle="tool-tip"
                                    title={"Add"}
                                    onClick={customVolumeInventory}
                                /></Button>
                            </div>
                        </div>
                    </div>}
                {isCustomVolumeDes &&
                    <div className="col-md-6 mt-2">
                        <Controller
                            name={'customVolumeDes'}
                            control={control}
                            render={({ field, fieldState }) => (
                                <DoInputField
                                    markReq={true}
                                    input={field}
                                    id={field.id}
                                    name={field.name}
                                    label={'custome Volume'}
                                    field={field}
                                    type={"text"}
                                    fieldState={fieldState}
                                    errors={errors}
                                    defVal={''}
                                    placeholder={"Volume"}
                                />
                            )}
                        />
                    </div>}

                <div className="col-md-6 mt-2">
                    <Controller
                        name={'lotId'}
                        control={control}
                        render={({ field, fieldState }) => (
                            <DoInputField
                                markReq={true}
                                input={field}
                                id={field.id}
                                name={field.name}
                                label={'Lot Id'}
                                field={field}
                                type={"text"}
                                fieldState={fieldState}
                                errors={errors}
                                defVal={''}
                                placeholder={"Lot Id"}
                            />
                        )}
                    />
                </div>
                <div className="col-md-6 mt-2">
                    <Controller
                        name={'expirationDate'}
                        control={control}
                        render={({ field, fieldState }) => (
                            <DoDateField
                                markReq={true}
                                input={field}
                                id={field.id}
                                name={field.name}
                                label={'Expiration Date'}
                                field={field}
                                type={"date"}
                                maxDate={null}
                                fieldState={fieldState}
                                errors={errors}
                                defVal={''}
                                placeholder={"Select Date"}
                            />
                        )}
                    />
                </div>
                <div className="col-md-6 mt-2">
                    <Controller
                        name={'facilityName'}
                        control={control}
                        render={({ field, fieldState }) => (
                            <DoInputField
                                markReq={false}
                                input={field}
                                id={field.id}
                                name={field.name}
                                label={'Facility'}
                                field={field}
                                type={"text"}
                                fieldState={fieldState}
                                errors={errors}
                                isDisable={true}
                                defVal={''}
                                placeholder={"Facility Name"}
                            />
                        )}
                    />
                </div>
                <div className="col-md-6 mt-2">
                    <Controller
                        name={'storageUnitName'}
                        control={control}
                        render={({ field, fieldState }) => (
                            <DoInputField
                                markReq={false}
                                input={field}
                                id={field.id}
                                name={field.name}
                                label={'Storage Unit'}
                                field={field}
                                type={"text"}
                                fieldState={fieldState}
                                errors={errors}
                                isDisable={true}
                                defVal={''}
                                placeholder={"StorageUnit Name"}
                            />
                        )}
                    />
                </div>
                <div className="col-md-6 mt-2">

                    <Controller
                        name={"storageUnitSubLocation"}
                        control={control}
                        render={({ field, fieldState }) => (
                            <DoSelectField
                                input={field}
                                markReq={true}
                                id={field.id}
                                name={field.name}
                                field={field}
                                label={"Cart SubLocations"}
                                fieldState={fieldState}
                                errors={errors}
                                options={cartSubLocation}
                                optionLabel={"value"}
                                placeholder={"Cart SubLocation"}
                                onChange={(e) => { getRequiredQuantityBasedOnCartSubLoc(e) }}

                            />)}
                    />
                </div>

                {
                    // currentInventry &&
                    <div className="col-md-6 mt-2">
                        <Controller
                            name={'count'}
                            control={control}
                            render={({ field, fieldState }) => (
                                <DoInputField
                                    markReq={false}
                                    input={field}
                                    id={field.id}
                                    name={field.name}
                                    label={'Current Inventory'}
                                    field={field}
                                    type={"text"}
                                    fieldState={fieldState}
                                    errors={errors}
                                    defVal={''}
                                    isDisable={true}
                                    placeholder={"Current Inventory"}
                                />
                            )}
                        />
                    </div>
                }
                {
                    // currentInventry &&
                    <div className="col-md-6 mt-2">
                        <Controller
                            name={'limit'}
                            control={control}
                            render={({ field, fieldState }) => (
                                <DoInputField
                                    markReq={false}
                                    input={field}
                                    id={field.id}
                                    name={field.name}
                                    label={'Required Inventory'}
                                    field={field}
                                    type={"text"}
                                    fieldState={fieldState}
                                    errors={errors}
                                    defVal={''}
                                    isDisable={true}
                                    placeholder={"Required Inventory"}
                                />
                            )}
                        />
                    </div>
                }
                <div className="col-md-6 mt-2">
                    <Controller
                        name={'addedInventory'}
                        control={control}
                        render={({ field, fieldState }) => (
                            <DoInputField
                                markReq={true}
                                input={field}
                                id={field.id}
                                name={field.name}
                                label={'Inventory Being Added'}
                                field={field}
                                type={"text"}
                                fieldState={fieldState}
                                errors={errors}
                                defVal={''}
                                placeholder={"Inventory"}
                            />
                        )}
                    />
                </div>

                {isBattery &&
                    <div className="col-md-6 mt-2">
                        <Controller
                            name={"checkType"}
                            control={control}
                            render={({ field, fieldState }) => (
                                <DoSelectField
                                    input={field}
                                    markReq={true}
                                    id={field.id}
                                    name={field.name}
                                    field={field}
                                    label={"Frequency"}
                                    fieldState={fieldState}
                                    errors={errors}
                                    options={checkTypes}
                                    optionLabel={"value"}
                                    placeholder={"CheckType"}
                                    onChange={(selectedValue) => {
                                        handleChange(selectedValue);
                                        setISCheckType(selectedValue);
                                    }}
                                />
                            )}
                        />
                    </div>}
                {ischeckType === 'Weekly' &&
                    <div className="col-md-6 mt-2">
                        <Controller
                            name={"batteryCheckDay"}
                            control={control}
                            render={({ field, fieldState }) => (
                                <DoSelectField
                                    input={field}
                                    markReq={true}
                                    id={field.id}
                                    name={field.name}
                                    field={field}
                                    label={"Battery Check Day"}
                                    fieldState={fieldState}
                                    errors={errors}
                                    options={BatteryCheckTypes}
                                    optionLabel={"value"}
                                    placeholder={"Battery Check Day"}
                                />
                            )}
                        />
                    </div>}
            </>
        )

    }

    // modal Header
    let getModalHeader = () => {
        let { t } = props;
        return (
            <ModalHeader className="modal__header">
                {/* <button className="lnr lnr-cross modal__close-btn" type="button" onClick={props.closeFormModal} /> */}
                <p className="bold-text  modal__title">Add Quantity</p>
            </ModalHeader>
        )
    }

    let closeFormModal = async () => {

        // props.reset();
        props.closeFormModal();
        // clearFormFields();
    }

    {/* Button */ }
    const getButtonToolbar = () => {

        return (
            <div className='d-flex mt-2'>
                <div className='col-12 px-0'>
                    <span className='float-end'>
                        <ButtonToolbar>
                            <Button color='primary' type="button" className='btn custom-outline' outline onClick={() => closeFormModal()}>
                                {'Cancel'}
                            </Button>
                            <Button color='primary' className='btn custom-outline' outlined type="submit">
                                {"Save"}
                            </Button>
                        </ButtonToolbar>
                    </span>
                </div>
            </div>
        )
    }



    // ModalBody
    let getModalBody = () => {
        return (

            <form onSubmit={handleSubmit(submit)} autoComplete={'off'}>
                <Loader loader={isLoading} />
                <div className="row form" >
                    {getFields()}

                </div>
                {getButtonToolbar()}
            </form>

        )
    }

    return (

        <div>

            <Dialog
                visible={props.openQuantitesModal}
                onHide={closeFormModal}
                draggable={false}
                style={{ width: '50vw' }}
                header={getModalHeader()}
                closeOnEscape={false}

            >
                {
                    <ModalBody className="modal__body mb-0 pt-4">
                        <Card className='pb-0 cardForListMargin'>
                            <CardBody className='tableCardBody'>
                                {getModalBody()}
                            </CardBody>
                        </Card>
                    </ModalBody>
                }
            </Dialog>
        </div>
    )
}

export default AddQuantitesModal